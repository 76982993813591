import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { CgMicrosoft } from "react-icons/cg";
import { FaApple } from "react-icons/fa6";
// import { Country, State, City } from "country-state-city";
import vliv_logo from "../../assets/Vliv logo-white.png";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
// import { adminLogin, companySignup } from "../../../src/constants/api";
// import moment from "moment-timezone";
import Cookies from "js-cookie";
import { GoogleLogin } from "@react-oauth/google";
import { notifyError, notifySuccess } from "../../Constrant/toastAlert";
// import { notifyError, notifySuccess } from "../../../src/constants/toastAlerts";
// import { useDispatch } from "react-redux";
// import { userLogin } from "../../redux/actions/login.action";
// import { LOGIN_SUCCCESS } from "../../redux/actionTypes";
// const allCountries = Country.getAllCountries();

const Auth = () => {
  const navigate = useNavigate();
  const isProduction = process.env.NODE_ENV === 'production';
  // const dispatch = useDispatch();
  //  for signin
  const [cred, setCred] = useState({
    email: "",
    password: "",
    module: "Klms",
    location: "",
    ip: "",
    city: "",
    country: "",
    state: "",
  });
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const handleClickShowLoginPassword = () =>
    setShowLoginPassword((show) => !show);
  const handleMouseDownLoginPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;

    setCred({ ...cred, [name]: value });
  };

  //for sign up
  const [data, setData] = useState([]);
  const [selectedCoutryTimezone, setSelectedCountryTimezone] = useState([]);
  const [selectedCountriesStates, SetSelectedCountriesStates] = useState([]);
  const [ispwdMissMatch, setIspwdMissMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });

    // if (name == "country") {
    //   // console.log(value);
    //   if (value) {
    //     setData((prev) => ({ ...prev, timezone: "", state: "" }));
    //     const selectedCountry = Country.getCountryByCode(value);
    //     console.log(selectedCountry);
    //     setData((prev) => ({
    //       ...prev,
    //       country: selectedCountry.name,
    //       currency: selectedCountry.currency,
    //       countrycode: selectedCountry.isoCode,
    //     }));
    //     const states = State.getStatesOfCountry(selectedCountry.isoCode) || [];
    //     // console.log(states,"states")
    //     SetSelectedCountriesStates((prev) => states);
    //     setSelectedCountryTimezone((prev) => selectedCountry.timezones);
    //   } else {
    //     setData((prev) => ({
    //       ...prev,
    //       country: "",
    //       currency: "",
    //       countrycode: "",
    //     }));
    //     SetSelectedCountriesStates([]);
    //     setSelectedCountryTimezone([]);
    //   }
    // }
  };

  const [isSignUp, setIsSignUp] = useState(false);
  const [progressValue, setProgressValue] = useState(50);
  const [tabvalue, setTabValue] = React.useState("step1");
  const [showEmptyError, setShowEmptyError] = useState(false);

  //for alert msg
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //for alert msg end

  // useEffect(() => {
  //   const checkLocationPermissionAndFetch = async () => {
  //     if (navigator.geolocation && navigator.permissions) {
  //       try {
  //         const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
  //         if (permissionStatus.state === 'granted') {
  //           getLocation(); // Fetch location if permission was previously granted
  //         }
  //       } catch (error) {
  //         console.log("Error checking geolocation permission:", error);
  //       }
  //     }
  //   };

  //   checkLocationPermissionAndFetch();
  // }, []);

  // Function to get the user's location
  // const getLocation = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         console.log("Location permission granted");
  //         const { latitude, longitude } = position.coords;
  //         getAddressFromCoordinates(latitude, longitude);
  //       },
  //       (error) => {
  //         console.log("Location permission denied or error occurred", error);
  //         if (error.message == "User denied Geolocation") {
  //           getIPDetails();
  //         }
  //         // console.error("Error fetching geolocation:", error);
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // };

  // const getIPDetails = async () => {
  //   try {
  //     const response = await axios.get("https://api.ipify.org?format=json")
  //     const ipAddress = response.data.ip;
  //     // Set the IP in the credentials
  //     setCred((prevCred) => ({
  //       ...prevCred,
  //       ip: ipAddress
  //     }));
  //     const getAddressFromIpDetails = await getLocationFromIpDetails(ipAddress);
  //     const { latitude, longitude } = getAddressFromIpDetails;
  //     getAddressFromCoordinates(latitude, longitude);
  //   }
  //   catch (error) {
  //     console.log('Error while fetching Ip details', error);
  //   }
  // }
  
  // const getLocationFromIpDetails = async (ip) => {
  //   try {
  //     // const response = await axios.get(`http://ip-api.com/json/${ip}`);
  //     const response = await axios.get(`https://ipapi.co/${ip}/json/`);
  //     // console.log(" ip res", response)
  //     const { city, region, country, org, latitude, longitude } = response.data;
  //     const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
  //     return {
  //       city,
  //       regionName: region,
  //       country,
  //       device,
  //       isp: org,
  //       latitude,
  //       longitude,
  //     };
  //   }
  //   catch (err) {
  //     console.log("Error while fetching location details", err)
  //   }
  // }

  // Function to reverse geocode and get the location name
  // const getAddressFromCoordinates = async (lat, lon) => {
  //   const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // Add your Google Maps API key here
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

  //   try {
  //     const response = await axios.get(url);
  //     const result = response.data.results[0];
  //     if (result) {
  //       const addressComponents = result.address_components;

  //       let city = "";
  //       let state = "";
  //       let country = "";

  //       addressComponents.forEach((component) => {
  //         if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         }
  //         if (component.types.includes("administrative_area_level_1")) {
  //           state = component.long_name;
  //         }
  //         if (component.types.includes("country")) {
  //           country = component.long_name;
  //         }
  //       });

  //       // Update the cred object with the location details
  //       setCred((prevCred) => ({
  //         ...prevCred,
  //         location: result.formatted_address, // Full address
  //         city: city,
  //         state: state,
  //         country: country
  //       }));
  //     } else {
  //       console.error("No results found for the given coordinates");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching location name:", error);
  //   }
  // };

  const handleLogin = async () => {
    if (!cred.email || !cred.password) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    else {
      // await getLocation();
      // if (!cred.location) {
      //   notifyError("For better experience!! Please allow your location.");
      // }
      // else {
        try {
          let res = await axios.post(
            "https://vliv.app/user/loginuser",
            cred
          );
          // dispatch(userLogin(res))
          if (res.data.message == "Login Successful") {
            Cookies.set("companytoken", res.data.token,
              {
                domain: isProduction ? '.vliv.app' : 'localhost',
                path: '/',
                secure: isProduction,
                sameSite: isProduction ? 'None' : 'Lax',
                expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
                expires: 1
              }
            );

            const {
              password,
              confirmpassword,
              yearlypremium,
              yearlystandard,
              yearlybasic,
              premium,
              standard,
              basic,
              addonpack,
              featureslist,
              purchaseaddonpack,
              ...userinfo
            } = res.data.data;
            Cookies.set("userinfo", JSON.stringify(userinfo), {
              domain: isProduction ? '.vliv.app' : 'localhost',
              path: '/',
              secure: isProduction,
              sameSite: isProduction ? 'None' : 'Lax',
              expires: 1
            });

            notifySuccess(res.data.message);
            // console.log(routes1[0].path, "rputes ")
            navigate("/kms/dashboard");
            setTimeout(() => {
              window.location.reload();
            }, 0);
          } else {
            setAlertType("error");
            notifyError(res.data.message);
          }
        } catch (error) {
          console.log(`Error while login ${error}`);
          notifyError(error);
        }
      // }

    }
  };

  // const handleSignUp = async () => {
  //   console.log(data, "data");
  //   try {
  //     let res = await axios.post("http://206.189.128.35:6001/user/loginuser", {
  //       ...data,
  //       joindate: data.joindate
  //         ? moment().tz(data.timezone).format("LLLL")
  //         : "",
  //     });
  //     if (res.data.message == "Registration successfully") {
  //       setAlertType("success");
  //       setOpen(true);
  //       setMsg(res.data.message);
  //       // setTimeout(() => {
  //       //   navigate("/signin");
  //       // }, 1000);
  //       setIsSignUp(false);
  //     } else {
  //       setAlertType("error");
  //       setOpen(true);
  //       setMsg(res.data.message);
  //     }
  //   } catch (error) {
  //     setAlertType("error");
  //     setOpen(true);
  //     setMsg("Something Went Wrong..");
  //   }
  // };
  const handleGoogle = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "http://206.189.128.35:6001/auth";

    document.body.appendChild(form);
    form.submit();
  };

  const handleError = () => {
    console.log("Login Failed");
  };
  return (
    <>
      <Box className="signin-main">
        <Box
          className="signin-left"
          width={"35%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <img src={vliv_logo} alt="logo" className="logo_img" />
            <p style={{ fontSize: "1.3rem" }}>Sign in an Account</p>
          </Box>
        </Box>
        {!isSignUp ? (
          <Box
            className="signin-right"
            width={"60%"}
            height={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="100%"
              maxWidth="430px"
              p={3}
              boxShadow={3}
              borderRadius={4}
              bgcolor="white"
            >
              <Typography
                component="h1"
                variant="h3"
                fontWeight={"700"}
                sx={{ fontSize: "1.5rem" }}
              >
                Sign in
              </Typography>
              {/* <Typography
                fontSize={"1.8rem"}
                component="h1"
                fontWeight={500}
                color="textSecondary"
                mt={1.3}
                mb={1.5}
              >
                New user?{" "}
                <span
                  className="primarycolor pointer"
                  onClick={() => setIsSignUp(true)}
                >
                  Create an account
                </span>
              </Typography> */}
              <Box>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ my: "0.6rem" }}
                  size="small"
                >
                  <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                    Email Address
                  </FormLabel>
                  <OutlinedInput
                    name="email"
                    value={cred.email}
                    onChange={handleLoginChange}
                    placeholder="Enter Your Mail Address"
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.2rem",
                      padding: 0.5,
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: "1.2rem", // Set the placeholder font size
                      },
                    }}
                    error={!cred.email && showEmptyError}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ my: "0.6rem", mb: 1 }}
                >
                  <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                    Password
                  </FormLabel>
                  <OutlinedInput
                    size="small"
                    name="password"
                    type={showLoginPassword ? "text" : "password"}
                    value={cred.password}
                    onChange={handleLoginChange}
                    placeholder="Enter Your Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowLoginPassword}
                          onMouseDown={handleMouseDownLoginPassword}
                        >
                          {showLoginPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.2rem",
                      padding: 0.5,
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: "1.2rem", // Set the placeholder font size
                      },
                    }}
                    error={!cred.password && showEmptyError}
                  />
                </FormControl>
                <div
                  className="button-container"
                  style={{
                    margin: "2rem 0rem 1rem 0rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={handleLogin}
                    sx={{
                      width: "100%",
                      mb: 1,
                      borderRadius: "25px",
                      border: "0.1px solid #98A2B3",
                      p: 1,
                      fontSize: "1.2rem",
                      textTransform: "none",
                      color: "#ffff",
                      backgroundColor: "#175376",
                      "&:hover": {
                        backgroundColor: "#175376", // Prevent background color change on hover
                      },
                    }}
                  >
                    Sign In
                  </Button>
                </div>
                {/* <Box
                  variant="body2"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={1.3}
                  my={2}
                >
                  <Box flex={2}>
                    <hr />
                  </Box>
                  <Box fontSize={"1rem"}>Or</Box>
                  <Box flex={2}>
                    <hr />
                  </Box>
                </Box>
                <Button
                  fullWidth
                  // variant="outlined"

                  onClick={handleGoogle}
                  startIcon={<FcGoogle />}
                  sx={{
                    mb: 1,
                    borderRadius: "25px",
                    border: "0.1px solid #98A2B3",
                    p: 1,
                    fontSize: "1.2rem",
                    textTransform: "none",
                    color: "black",
                  }}
                >
                  Continue with Google
                </Button>
              <Button
                  fullWidth
                  // variant="outlined"

                  startIcon={<FaApple />}
                  sx={{
                    mb: 1,
                    borderRadius: "25px",
                    border: "0.1px solid #98A2B3",
                    p: 1,
                    fontSize: "1.5rem",
                    textTransform: "none",
                    color: "black",
                  }}
                >
                  Continue with Apple
                </Button> */}

                {/* <Button
                  fullWidth
                  startIcon={<CgMicrosoft />}
                  sx={{
                    mb: 1,
                    borderRadius: "25px",
                    border: "0.1px solid #98A2B3",
                    p: 1,
                    fontSize: "1.5rem",
                    textTransform: "none",
                    color: "black",
                  }}
                >
                  Continue with Microsoft
                </Button> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            className="signin-right"
            width={"60%"}
            height={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="100%"
              maxWidth="430px"
              p={3}
              boxShadow={3}
              borderRadius={4}
              bgcolor="white"
            >
              <TabContext value={tabvalue}>
                <TabPanel value="step1" sx={{ padding: "0" }}>
                  <Typography component="h1" variant="h3" fontWeight={"700"}>
                    Create Account
                  </Typography>
                  <Typography
                    fontSize={"1.8rem"}
                    component="h1"
                    fontWeight={500}
                    color="textSecondary"
                    mt={1.3}
                    mb={1}
                  >
                    Already have account?{" "}
                    <span
                      className="primarycolor pointer"
                      onClick={() => setIsSignUp(false)}
                    >
                      Sign In
                    </span>
                  </Typography>
                  <Box component="form" noValidate>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ margin: "0.6rem" }}
                      size="small"
                    >
                      <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                        Email Address
                      </FormLabel>
                      <OutlinedInput
                        name="clientemail"
                        // size="small"
                        autoComplete="clientemail"
                        value={data.clientemail}
                        onChange={handleChange}
                        placeholder="Enter Your Mail Address"
                        sx={{
                          borderRadius: "25px",
                          fontSize: "1.5rem",
                          padding: 0.5,
                        }}
                      />
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ margin: "0.6rem" }}
                      size="small"
                    >
                      <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                        Password
                      </FormLabel>
                      <OutlinedInput
                        type={showPassword ? "text" : "password"}
                        value={data.password}
                        onChange={handleChange}
                        name="password"
                        placeholder="Enter Your Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          borderRadius: "25px",
                          fontSize: "1.5rem",
                          padding: 0.5,
                        }}
                      />
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ margin: "0.6rem" }}
                      size="small"
                    >
                      <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                        Confirm Password
                      </FormLabel>
                      <OutlinedInput
                        id="password"
                        value={data.confirmpassword}
                        onChange={handleChange}
                        error={!data.confirmpassword && showEmptyError}
                        name="confirmpassword"
                        color={
                          data.password && data.password == data.confirmpassword
                            ? "success"
                            : "primary"
                        }
                        sx={{
                          borderRadius: "25px",
                          fontSize: "1.5rem",
                          padding: 0.5,
                        }}
                      />
                    </FormControl>
                    <div
                      className="button-container"
                      style={{
                        margin: "2rem 0rem 1rem 0rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="commonBtn"
                        onClick={() => {
                          if (data.password !== data.confirmpassword) {
                            notifyError("Password Mismatched");
                          } else {
                            setTabValue("step2");
                          }
                        }}
                        style={{ width: "65%", textAlign: "center" }}
                        disabled={
                          !data.clientemail ||
                          !data.password ||
                          !data.confirmpassword
                        }
                      >
                        Continue
                      </button>
                    </div>
                    <Box
                      variant="body2"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={1.3}
                      mt={1}
                      mb={2.5}
                    >
                      <Box flex={2}>
                        <hr />
                      </Box>
                      <Box fontSize={"1.6rem"}>Or</Box>
                      <Box flex={2}>
                        <hr />
                      </Box>
                    </Box>
                    <Button
                      fullWidth
                      // variant="outlined"

                      onClick={handleGoogle}
                      startIcon={<FcGoogle />}
                      sx={{
                        mb: 1,
                        borderRadius: "25px",
                        border: "0.1px solid #98A2B3",
                        p: 1,
                        fontSize: "1.5rem",
                        textTransform: "none",
                        color: "black",
                      }}
                    >
                      Continue with Google
                    </Button>
                    <Button
                      fullWidth
                      // variant="outlined"

                      startIcon={<FaApple />}
                      sx={{
                        mb: 1,
                        borderRadius: "25px",
                        border: "0.1px solid #98A2B3",
                        p: 1,
                        fontSize: "1.5rem",
                        textTransform: "none",
                        color: "black",
                      }}
                    >
                      Continue with Apple
                    </Button>
                    <Button
                      fullWidth
                      startIcon={<CgMicrosoft />}
                      sx={{
                        mb: 1,
                        borderRadius: "25px",
                        border: "0.1px solid #98A2B3",
                        p: 1,
                        fontSize: "1.5rem",
                        textTransform: "none",
                        color: "black",
                      }}
                    >
                      Continue with Microsoft
                    </Button>
                  </Box>
                </TabPanel>
                <TabPanel value="step2" sx={{ padding: "0" }}>
                  <Typography
                    component="h1"
                    variant="h3"
                    fontWeight={"700"}
                    mb={1}
                  >
                    Additional Information
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{ marginY: 1 }}
                  />

                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Name
                    </FormLabel>
                    <OutlinedInput
                      name="clientname"
                      autoComplete="clientname"
                      value={data.clientname}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Name"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Organization Name
                    </FormLabel>
                    <OutlinedInput
                      name="company"
                      autoComplete="company"
                      value={data.company}
                      onChange={handleChange}
                      placeholder="Enter Organization Name"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    />
                  </FormControl>
                  <FormControl size="small" fullWidth variant="outlined">
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Industry Type
                    </FormLabel>
                    <Select
                      value={data.industrytype}
                      onChange={handleChange}
                      name="industrytype"
                      placeholder="Industry Type"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      <MenuItem value="Consulting" sx={{ fontSize: "1.5rem" }}>
                        Consulting
                      </MenuItem>
                      <MenuItem
                        value="Construction"
                        sx={{ fontSize: "1.5rem" }}
                      >
                        Construction
                      </MenuItem>
                      <MenuItem value="Education" sx={{ fontSize: "1.5rem" }}>
                        Education
                      </MenuItem>
                      <MenuItem value="Engineering" sx={{ fontSize: "1.5rem" }}>
                        Engineering
                      </MenuItem>
                      <MenuItem value="Web Design" sx={{ fontSize: "1.5rem" }}>
                        Web Design
                      </MenuItem>
                      <MenuItem
                        value="Web Development"
                        sx={{ fontSize: "1.5rem" }}
                      >
                        Web Development
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Industry Size
                    </FormLabel>
                    <Select
                      value={data.industrysize}
                      onChange={handleChange}
                      name="industrysize"
                      placeholder="Industry Size"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      <MenuItem value="0-50" sx={{ fontSize: "1.5rem" }}>
                        0-50
                      </MenuItem>
                      <MenuItem value="50-100" sx={{ fontSize: "1.5rem" }}>
                        50-100
                      </MenuItem>
                      <MenuItem value="100-500" sx={{ fontSize: "1.5rem" }}>
                        100-500
                      </MenuItem>
                      <MenuItem value="500-1000" sx={{ fontSize: "1.5rem" }}>
                        500-1000
                      </MenuItem>
                      <MenuItem value="1000-10000" sx={{ fontSize: "1.5rem" }}>
                        1000-10000
                      </MenuItem>
                      <MenuItem value="10000+" sx={{ fontSize: "1.5rem" }}>
                        10000+
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div
                    className="button-container"
                    style={{
                      margin: "2rem 0rem 1rem 0rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      className="commonBtn"
                      onClick={() => {
                        setTabValue("step3");
                        setProgressValue(100);
                      }}
                      style={{ width: "40%", textAlign: "center" }}
                    >
                      Continue
                    </button>
                  </div>
                </TabPanel>
                <TabPanel value="step3" sx={{ padding: "0" }}>
                  <Typography
                    component="h1"
                    variant="h3"
                    fontWeight={"700"}
                    mb={1}
                  >
                    Additional Information
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{ marginY: 1 }}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Country
                    </FormLabel>
                    <Select
                      id="industry-type"
                      onChange={handleChange}
                      name="country"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      {/* {allCountries?.map((country, ind) => (
                        <MenuItem
                          value={country.isoCode}
                          key={ind}
                          sx={{ textAlign: "left", fontSize: "1.5rem" }}
                        >
                          {country.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      State
                    </FormLabel>
                    <Select
                      value={data.state}
                      onChange={handleChange}
                      name="state"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      {selectedCountriesStates?.map((state, ind) => (
                        <MenuItem
                          value={state.name}
                          key={ind}
                          sx={{ textAlign: "left", fontSize: "1.5rem" }}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Time Zone
                    </FormLabel>
                    <Select
                      value={data.timezone}
                      onChange={handleChange}
                      name="timezone"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      {" "}
                      {selectedCoutryTimezone?.map((timeZone, ind) => (
                        <MenuItem
                          value={timeZone.zoneName}
                          key={ind}
                          sx={{ textAlign: "left", fontSize: "1.5rem" }}
                        >
                          {timeZone.zoneName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Currency
                    </FormLabel>
                    <OutlinedInput
                      type="text"
                      name="currency"
                      autoComplete="currency"
                      readOnly
                      value={data.currency}
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                      Language
                    </FormLabel>
                    <Select
                      value={data.language}
                      onChange={handleChange}
                      name="language"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.5rem",
                        padding: 0.5,
                      }}
                    >
                      <MenuItem
                        value="English"
                        sx={{ textAlign: "left", fontSize: "1.5rem" }}
                        onChange={handleChange}
                      >
                        English
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div
                    className="button-container"
                    style={{ margin: "2rem 0rem 1rem 0rem" }}
                  >
                    <button
                      className="cancelbtn"
                      onClick={() => {
                        setTabValue("step2");
                        setProgressValue(50);
                      }}
                    >
                      Fill Later
                    </button>
                    {/* <button className="commonBtn" onClick={handleSignUp}>
                      Save
                    </button> */}
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Auth;
