import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PiGraduationCapLight } from "react-icons/pi";
import { GiProgression } from "react-icons/gi";
import ReactApexChart from "react-apexcharts";
import CanvasJSReact from "@canvasjs/react-charts";
import {
  MdGroups,
  MdOutlineAssessment,
  MdOutlineQuiz,
  MdOutlineSmsFailed,
} from "react-icons/md";
import { AiOutlineDotChart } from "react-icons/ai";
import { FaBookReader, FaRegFileAlt } from "react-icons/fa";
import { FiUserPlus, FiUserX } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";
import { IoNewspaperOutline } from "react-icons/io5";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
//var CanvasJSReact = require('@canvasjs/react-charts');
import { SiProgress } from "react-icons/si";
import { SiPassport } from "react-icons/si";
import { GiGraduateCap } from "react-icons/gi";
import Blue from "../../../assets/Blue.png";
import Green from "../../../assets/Green.png";
import Purple from "../../../assets/Purple.png";
import Yellow from "../../../assets/Yellow.png";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DashboardView() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userrole = userInfo.userrole;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;

  const [totalCourse, setTotalCourse] = useState("");
  const [learnersEmails, setLearnersEmails] = useState([]);
  const [totalQuiz, setTotalQuiz] = useState([]);
  const [totalAssessment, setTotalAssessment] = useState([]);
  const [totalAssigneAssessment, setTotalAssignAssement] = useState([]);
  const [totalAssignQuiz, setTotalAssignQuiz] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [allUsers, setAllUsers] = useState(0);
  const [greeting, setGreeting] = useState("");
  const [pass, setPass] = useState([]);
  const [fail, setFail] = useState([]);
  const [allPassFailData, setAllPassFailData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // for completed
  const [completed, setCompleted] = useState([]);
  // console.log(completed.size, "complated")
  const [greetingColr, setGreetingClr] = useState("black");

  // For the assessment data including filter start
  // const [totalAssessment, setTotalAssessment] = useState([]);
  // const [totalQuiz, setTotalQuiz] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [selectedYearAssess, setSelectedYearAssess] = useState(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  const fetchAssessmentsAndQuizzes = () => {
    axios
      .post(`https://klms.vliv.app/assessment/get`, { companyid, userrole })
      .then((res) => {
        const assessmentData = res.data.filter(
          (elem) => elem.type === "Assessment"
        );
        setTotalAssessment(assessmentData);
        const quizData = res.data.filter((elem) => elem.type === "Quiz");
        setTotalQuiz(quizData);
        filterData(assessmentData, quizData, selectedYearAssess, selectedMonth);
      })
      .catch((err) => console.log(err));
  };

  const filterData = (assessments, quizzes, year, month) => {
    const filterByDate = (data) => {
      return data.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return itemDate.getFullYear() === year && itemDate.getMonth() === month;
      });
    };

    setFilteredAssessments(filterByDate(assessments));
    setFilteredQuizzes(filterByDate(quizzes));
  };

  useEffect(() => {
    fetchAssessmentsAndQuizzes();
  }, [companyid, userrole]);

  useEffect(() => {
    filterData(totalAssessment, totalQuiz, selectedYearAssess, selectedMonth);
  }, [selectedYearAssess, selectedMonth, totalAssessment, totalQuiz]);

  const handlePreviousMonth = () => {
    if (selectedMonth === 0) {
      setSelectedMonth(11);
      setSelectedYearAssess(selectedYearAssess - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0);
      setSelectedYearAssess(selectedYearAssess + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };
  // For the assessment data including filter end

  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good Morning!");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon!");
    } else {
      setGreeting("Good Evening!");
      setGreetingClr("white");
    }

    axios
      .post(`https://klms.vliv.app/course/get`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        console.log(res.data, "courses data");
        setTotalCourse(res.data.length);
        const emails = extractUniqueEmails(res.data);
        setLearnersEmails(emails);
        const monthlyData = processMonthlyData(res.data, selectedYear);
        setMonthlyData(monthlyData);
      })
      .catch((err) => console.log(err));

    //To get total assessment and quiz
    // axios
    //   .post(`https://klms.vliv.app/assessment/get`, { companyid, userrole })
    //   .then((res) => {
    //     console.log(res.data, "assessments data");
    //     //For assessment
    //     const assessmentData = res.data.filter((elem) => {
    //       return elem.type == "Assessment";
    //     });
    //     setTotalAssessment(assessmentData);
    //     const assessData = extractUniqueAssessment(assessmentData);
    //     setTotalAssignAssement(assessData);
    //     //For quiz
    //     const quizData = res.data.filter((elem) => {
    //       return elem.type == "Quiz";
    //     });
    //     setTotalQuiz(quizData);
    //     const quizAssignData = extractUniqueAssessment(quizData);
    //     setTotalAssignQuiz(quizAssignData);
    //   })
    //   .catch((err) => console.log(err));

    // To get All users
    axios
      .post(`https://klms.vliv.app/course/allusers`, {
        companyid: companyid.toString(),
      })
      .then((res) => {
        // console.log(res.data, "all users");
        setAllUsers(res.data.length);
      })
      .catch((err) => console.log(err));

    // Pass fail
    axios
      .post(`https://klms.vliv.app/assessment/getallpass`, {
        companyid,
        userrole,
      })
      .then((res) => {
        const passData = res.data.filter((elem) => {
          return elem.passfail == "pass";
        });
        const failData = res.data.filter((elem) => {
          return elem.passfail == "fail";
        });

        setPass(passData);
        setFail(failData);
        setAllPassFailData(res.data);
        console.log(res.data, "get all pass");
      })
      .catch((err) => console.log(err));

    //Certificate table to get completed course
    axios
      .post(`https://klms.vliv.app/certificate/getall`, {
        companyid,
        userrole,
        userid,
      })
      .then((res) => {
        const userSet = new Set();
        res.data.forEach((item) => userSet.add(item.email));
        setCompleted(userSet);
        // console.log(userSet, "all certificate");
      })
      .catch((err) => console.log(err));
  }, [companyid, selectedYear]);

  // console.log(allUsers, "all users");

  //   To get how many learners from assign column
  const extractUniqueEmails = (data) => {
    const emailSet = new Set();

    data.forEach((course) => {
      if (course.assign) {
        course.assign.forEach((email) => emailSet.add(email));
      }
    });

    return Array.from(emailSet);
  };

  //   To get assessment how many user have acceess
  const extractUniqueAssessment = (data) => {
    const assessmentSet = new Set();

    data.forEach((item) => {
      if (item.access) {
        item.access.forEach((data) => assessmentSet.add(data));
      }
    });

    return Array.from(assessmentSet);
  };

  // ChartJs for compliance column chart start
  const [columnOption, setColumnOption] = useState({
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "column",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: [],
      },
      {
        type: "column",
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: [],
      },
    ],
  });

  useEffect(() => {
    const fetchComplianceData = async () => {
      try {
        const complianceResponse = await axios.post(
          `https://klms.vliv.app/compliance/get`,
          { companyid }
        );
        const compliances = complianceResponse.data;

        const usersResponse = await axios.post(
          "https://klms.vliv.app/course/allusers",
          {
            companyid: companyid.toString(),
          }
        );
        const users = usersResponse.data;

        const updatedDataPoints = compliances.map((compliance) => {
          const agreedUsersCount = users.filter(
            (user) =>
              user.companyid === companyid &&
              compliance.agreed &&
              compliance.agreed.includes(user.email)
          ).length;
          const totalUsersCount = users.filter(
            (user) => user.companyid === companyid
          ).length;
          const pendingUsersCount = totalUsersCount - agreedUsersCount;

          return {
            compliancename: compliance.compliancename,
            agreed: agreedUsersCount,
            pending: pendingUsersCount,
          };
        });

        const newColumnOption = {
          ...columnOption,
          data: [
            {
              ...columnOption.data[0],
              dataPoints: updatedDataPoints.map((data) => ({
                label: data.compliancename,
                y: data.agreed,
                color: "#D6BBFB",
              })),
            },
            {
              ...columnOption.data[1],
              dataPoints: updatedDataPoints.map((data) => ({
                label: data.compliancename,
                y: data.pending,
                color: "#7CD4FD",
              })),
            },
          ],
        };

        setColumnOption(newColumnOption);
      } catch (error) {
        console.error("Error fetching compliance or user data:", error);
      }
    };

    fetchComplianceData();
  }, []);

  // Process data to extract monthly information
  const processMonthlyData = (data, year) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthlyData = monthNames.map((month) => ({
      month,
      courseCount: 0,
      userCount: 0,
    }));

    data.forEach((course) => {
      const courseYear = new Date(course.createdAt).getFullYear();
      if (courseYear === year) {
        const monthIndex = new Date(course.createdAt).getMonth();
        monthlyData[monthIndex].courseCount += 1;
        if (course.assign) {
          monthlyData[monthIndex].userCount += course.assign.length;
        }
      }
    });

    return monthlyData;
  };

  const barOption = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "bar",
        name: "course contribution",
        legendText: "course contribution",
        dataPoints: monthlyData.map((data) => ({
          label: data.month,
          y: data.courseCount,
          color: "#D6BBFB",
        })),
      },
      {
        type: "bar",
        name: "enrolled courses",
        legendText: "enrolled courses",
        dataPoints: monthlyData.map((data) => ({
          label: data.month,
          y: data.userCount,
          color: "#7CD4FD",
        })),
      },
    ],
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} p={1}>
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box>
              {greeting == "Good Morning!" ? (
                <img
                  src={require("../../../assets/morning.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : greeting == "Good Afternoon!" ? (
                <img
                  src={require("../../../assets/afternoon.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              ) : (
                <img
                  src={require("../../../assets/night.png")}
                  alt="Image not available"
                  style={{
                    borderRadius: "25px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 30,
                // left: "10vh",
                color: "#FFFF",
                fontWeight: "bold",
                zIndex: 1,
                background:"black",
                padding:"10px 10px 10px 50px",
                opacity:0.7,
                borderRadius:"10px"
              }}
            >
              <Typography
                variant="h4"
                sx={{
                //   position: "absolute",
                //   top: 30,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                //   zIndex: 1,
                }}
              >
                {greeting}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                //   position: "absolute",
                //   top: 60,
                //   left: "10vh",
                //   color: greetingColr,
                  fontWeight: "bold",
                  fontSize: "20px",
                //   zIndex: 1,
                }}
              >
                Howdy, {username}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Course asessment */}
        <Grid
          container
          sx={{
            width: "100%",
          }}
        >
          <Grid item lg={6} md={6} sm={12} xs={12} p={1}>
            <Typography
              sx={{
                mt: 2,
                mb: 2,
                ml: 2,
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <PiGraduationCapLight />
              <span> Courses</span>
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              p={1}
              sx={{
                alignItems: "center",
                paddingLeft: 1,
                paddingRight: 1,
                borderRadius: 3,
                width: "100%",
                // height: "30vh",
                display: "flex",
                gap: 3,
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                p={1}
                sx={{
                  background: "#FEF0C7",
                  width: "40vh",
                  // height: "25vh",
                  textAlign: "center",
                  borderRadius: "15px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <GiGraduateCap
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "12%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.2, // Adjust opacity as needed
                  }}
                />
                <img
                  src={Yellow}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "75%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.8, // Adjust opacity as needed
                  }}
                />
                <Typography
                  sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}
                >
                  Total Courses
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                >
                  {totalCourse}
                </Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                p={1}
                sx={{
                  background: "#F4EBFF",
                  width: "40vh",
                  // height: "25vh",
                  textAlign: "center",
                  borderRadius: "15px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <FaBookReader
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "15%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.2, // Adjust opacity as needed
                  }}
                />

                <img
                  src={Purple}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "75%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.8, // Adjust opacity as needed
                  }}
                />

                <Typography
                  sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}
                >
                  Total Learners
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                >
                  {+learnersEmails.length}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              p={1}
              sx={{
                alignItems: "center",
                paddingLeft: 1,
                paddingRight: 1,
                borderRadius: 3,
                width: "100%",
                // height: "30vh",
                display: "flex",
                gap: 3,
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                p={1}
                sx={{
                  background: "#E0F2FE",
                  width: "40vh",
                  textAlign: "center",
                  borderRadius: "15px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <SiProgress
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "10%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.2, // Adjust opacity as needed
                  }}
                />

                <img
                  src={Blue}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "71%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.8, // Adjust opacity as needed
                  }}
                />

                <Typography
                  sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}
                >
                  In Progress
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                >
                  {!completed
                    ? learnersEmails.length
                    : +(learnersEmails.length - +completed.size)}
                </Typography>
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={12}
                xs={12}
                p={1}
                sx={{
                  background: "#D1FADF",
                  width: "40vh",
                  // height: "25vh",
                  textAlign: "center",
                  borderRadius: "15px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <SiPassport
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "10%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.2, // Adjust opacity as needed
                  }}
                />

                <img
                  src={Green}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "71%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "100px", // Adjust size as needed
                    opacity: 0.8, // Adjust opacity as needed
                  }}
                />

                <Typography
                  sx={{ fontSize: "20px", fontWeight: "bold", mt: 1 }}
                >
                  Completed
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "35px", mt: 3 }}
                >
                  {completed ? +completed.size : 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* For assessment */}
          <Grid item lg={6} md={6} sm={12} xs={12} p={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 2, fontWeight: "bold", fontSize: "1.2rem" }}>
                <MdOutlineAssessment />
                <span> Assessments</span>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handlePreviousMonth}>
                  <ArrowBackIos />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {new Date(selectedYearAssess, selectedMonth).toLocaleString(
                    "default",
                    { month: "short" }
                  )}{" "}
                  {selectedYearAssess}
                </Typography>
                <IconButton onClick={handleNextMonth}>
                  <ArrowForwardIos />
                </IconButton>
              </Box>
            </Box>
            <Grid item lg={12} md={12} sm={12} xs={12} p={1}>
              <Typography
                sx={{ mt: 2, fontWeight: "bold", fontSize: "1.2rem" }}
              >
                <span> Assessments</span>
              </Typography>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Total Assessments
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FaRegFileAlt />
                    <span> {+filteredAssessments.length}</span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Assigned
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FiUserPlus color="green" />
                    <span>
                      {" "}
                      {+filteredQuizzes.filter((q) =>  (q.type == 'Assessment' && q.access && q.access.length>0)).length}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Not Assigned
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FiUserX color="red" />
                    <span>
                      {" "}
                      {+filteredAssessments.filter((a) => (a.type == 'Assessment' && !a.access || a.access.length <=0)).length}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{ mt: 2, fontWeight: "bold", fontSize: "1.2rem" }}
              >
                <span> Quizzes</span>
              </Typography>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Total Quizzes
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FaRegFileAlt /> <span>{+filteredQuizzes.length}</span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Assigned
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FiUserPlus color="green" />
                    <span>
                      {" "}
                      {+filteredQuizzes.filter((q) => (q.type == 'Quiz' && q.access && q.access.length>0)).length}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#FFFF",
                    width: "30vh",
                    textAlign: "center",
                    borderRadius: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", mt: 1 }}>
                    Not Assigned
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "25px", mt: 3 }}
                  >
                    <FiUserX color="red" />
                    <span>
                      {" "}
                      {+filteredQuizzes.filter((q) => (q.type == 'Quiz' && !q.access || q.access.length<=0)).length}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* contribution and compliance */}
        <Grid container>
          {/* For Contribution */}
          <Grid item lg={6} md={6} sm={12} xs={12} p={1}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  mt: 3,
                  mb: 1,
                  ml: 1,
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {" "}
                <AiOutlineDotChart />
                <span> Contribution</span>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                <IconButton
                  disabled={selectedYear <= new Date().getFullYear() - 5}
                  onClick={() => setSelectedYear(selectedYear - 1)}
                >
                  <ArrowBackIos />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {selectedYear}
                </Typography>
                <IconButton
                  disabled={selectedYear >= new Date().getFullYear()}
                  onClick={() => setSelectedYear(selectedYear + 1)}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>
              <CanvasJSChart
                options={barOption}
                containerProps={{ width: "100%", height: "400px" }}
              />
              <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      background: "#D6BBFB",
                      width: "20px",
                      height: "13px",
                      margin: "4px",
                    }}
                  ></Card>{" "}
                  Courses contribution
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      background: "#7CD4FD",
                      width: "20px",
                      height: "13px",
                      margin: "4px",
                    }}
                  ></Card>{" "}
                  Enrolled courses
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* For compliance */}
          <Grid item lg={6} md={6} sm={12} xs={12} p={1}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  mt: 3,
                  mb: 1,
                  ml: 1,
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                <AiOutlineDotChart />
                <span> Compliance</span>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end", mb: 7 }}></Box>
              <CanvasJSChart
                options={columnOption}
                containerProps={{ width: "100%", height: "400px" }}
              />
              <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      background: "#D6BBFB",
                      width: "20px",
                      height: "13px",
                      margin: "4px",
                    }}
                  ></Card>
                  Accept
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      background: "#7CD4FD",
                      width: "20px",
                      height: "13px",
                      margin: "4px",
                    }}
                  ></Card>
                  Pending
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    
    </>
  );
}

export default DashboardView;
